import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import cookies from 'vue-cookies'
import './assets/css/style.css';
import VueWechatTitle from 'vue-wechat-title';

Vue.use(VueWechatTitle)

Vue.config.productionTip = false
Vue.prototype.$cookies = cookies;

Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')


