import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
    mode: 'hash',
    // base: process.env.BASE_URL,
    base: '/',
    routes: [{
        path: '/',
        name: 'home',
        component: () => import('./views/Home'),
        meta: {
            title: process.env.VUE_APP_HOME_TITLE + process.env.VUE_APP_HOME_TEST
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login'),
        meta: {
            title: process.env.VUE_APP_HOME_TITLE + process.env.VUE_APP_HOME_TEST + '-登录'
        }
    }, {
        path: '/manual',
        name: 'manual',
        component: () => import('./views/Manual'),
        meta: {
            title: '百家号发布' + process.env.VUE_APP_HOME_TEST
        }
    }, {
        path: '/download',
        name: 'download',
        component: () => import('./views/Download'),
        meta: {
            title: '下载'
        }
    }, {
        path: '/ystlogin',
        name: 'ystlogin',
        component: () => import('./views/YstLogin'),
        meta: {
            title: '亿视推授权' + process.env.VUE_APP_HOME_TEST + '-登录'
        }
    }, {
        path: '/ystindex',
        name: 'ystindex',
        component: () => import('./views/YstUsers'),
        meta: {
            title: '亿视推授权' + process.env.VUE_APP_HOME_TEST
        }
    }, , {
        path: '/ystusers',
        name: 'ystusers',
        component: () => import('./views/YstUsers'),
        meta: {
            title: '亿视推授权' + process.env.VUE_APP_HOME_TEST
        }
    },
    {
        path: '/jigouhao',
        name: 'jigouhao',
        component: () => import('./views/Jigouhao'),
        meta: {
            title: '机构号授权' + process.env.VUE_APP_HOME_TEST
        }
    }, {
        path: '/device',
        name: 'device',
        component: () => import('./views/Device'),
        meta: {
            title: '自动化管理' + process.env.VUE_APP_HOME_TEST
        }
    }, {
        path: '/watch',
        name: 'watch',
        component: () => import('./views/watch/Menu'),
        children:[
           
            {
                path: '/watch/hecheng',
                name: 'watch-hecheng',
                component: () => import('./views/watch/Hecheng'),
                meta: {
                    title: '合成列表' + process.env.VUE_APP_HOME_TEST
                },
            },
            {
                path: '/watch/publish',
                name: 'watch-hecheng',
                component: () => import('./views/watch/Publish'),
                meta: {
                    title: '合成列表' + process.env.VUE_APP_HOME_TEST
                },
            },
            {
                path: '/watch/jigouhao',
                name: 'watch-jigouhao',
                component: () => import('./views/watch/Jigouhao'),
                meta: {
                    title: '机构号列表' + process.env.VUE_APP_HOME_TEST
                },
            }
        ]
    }]
})