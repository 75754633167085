<meta http-equiv="Content-Security-Policy" content="default-src 'self'"></meta>
<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <router-view v-wechat-title='$route.meta.title'></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
